<template>
    <div class="relative flex h-full overflow-auto scroll soloveyko-scroll-xs transition lg:rounded-lg">

        <div class="absolute left-0 top-0 w-full min-h-full overflow-hidden flex flex-col">

            <ul class="flex flex-col flex-nowrap w-full h-fit relative">
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER])" class="overflow-hidden w-ful cursor-pointer">
                    <router-link :to="{ name: 'Location', params: { locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <MapPinIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.locations") }}
                        </div>
                    </router-link>
                </li>
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN])" class="overflow-hidden w-full cursor-pointer">
                    <router-link :to="{ name: 'Ether', params: { locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <PlayIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.ethers") }}
                        </div>
                    </router-link>
                </li>
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN])" class="overflow-hidden w-full cursor-pointer">
                    <router-link :to="{ name: 'AdCampaign', params: { locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <BanknotesIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.advertisement") }}
                        </div>
                    </router-link>
                </li>
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN])" class="overflow-hidden w-full cursor-pointer">
                    <router-link :to="{ name: 'Report', params: { locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <ChartBarIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.reports") }}
                        </div>
                    </router-link>
                </li>
                
                <!-- v-if here is to hide divider if there are no navbar options displayed -->
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER])">
                    <div class="divider m-0 p-0 select-none"></div>
                </li>

                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN])" class="overflow-hidden w-full cursor-pointer">
                    <router-link :to="{ name: 'Playlist', params: { mediaType: 'audio', locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <MusicalNoteIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.audioLibrary") }}
                        </div>
                    </router-link>
                </li>
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN])" class="overflow-hidden w-full cursor-pointer justify-center">
                    <router-link :to="{ name: 'Playlist', params: { mediaType: 'video', locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <VideoCameraIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.videoLibrary") }}
                        </div>
                    </router-link>
                </li>
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN])" class="overflow-hidden w-full cursor-pointer">
                    <router-link :to="{ name: 'Browser', params: { locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <GlobeAltIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.browserScreens") }}
                        </div>
                    </router-link>
                </li>

                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LISTENER])" class="overflow-hidden w-full cursor-pointer">
                    <router-link :to="{ name: 'SoloveykoBrowser', params: { locale: $i18n.locale } }"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" active-class="s-text-primary s-navbar-item-active hover:s-navbar-item-active-hover"
                        @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <SpeakerWaveIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.soloveykoBrowser") }}
                        </div>
                    </router-link>
                </li>

                <!-- v-if here is to hide divider if there are no navbar options displayed -->
                <li v-if="accessAllowed([ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LISTENER])">
                    <div class="divider m-0 p-0 select-none"></div>
                </li>

                <li class="overflow-hidden w-full hover:s-navbar-item-hover cursor-pointer rounded-b-lg">
                    <a href="https://doc.clickup.com/30376499/p/h/wz0hk-4444/96f5962cf1dab3a/wz0hk-4444"
                        class="flex flex-row flex-nowrap h-12 relative hover:s-navbar-item-hover hover:s-text-primary"
                        :class="{ 'lg:justify-center': navBarStore.menuCollapsed }" target="_blank" @click="hideMobileMenu">
                        <div class="flex w-16 h-full justify-center items-center shrink-0">
                            <CodeBracketIcon class="flex w-6 h-6" />
                        </div>
                        <div class="flex truncate items-center select-none"
                            :class="{ 'lg:hidden': navBarStore.menuCollapsed }">
                            {{ $t("navbar.changelog") }}
                        </div>
                    </a>
                </li>
                <div class="absolute hidden lg:flex w-full h-full inset-0 s-navbar-bg z-[-1] rounded-lg" />

            </ul>
        </div>

    </div>
</template>
<script setup>
import "@/styles/scroll.scss";

import {
    BanknotesIcon,
    GlobeAltIcon,
    PlayIcon,
    MusicalNoteIcon,
    VideoCameraIcon,
    MapPinIcon,
    ChartBarIcon,
    CodeBracketIcon,
    SpeakerWaveIcon
} from "@heroicons/vue/24/outline";

import { useNavBarStore } from "@/store/navBarStore";
import { accessAllowed } from '@/helpers/accessHelper.js'
import { ACCESS_LEVEL } from '@/dictionaries/contacts'

const hideMobileMenu = () => {
    navBarStore.hideMobileMenu();
}

const navBarStore = useNavBarStore();

</script>
