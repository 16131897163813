import soloveykoApi from '@/api/soloveyko.api'

class OwnerApi {
  constructor() {
  }
  async createOwner(owner) {
    let response = await soloveykoApi.post("/owner", {
      ...owner
    })
    return response.data.owner
  }

  async updateOwner(owner) {
    let response = await soloveykoApi.put("/owner", {
      owner_id: owner.id,
      ...owner
    })
    return response.data.owner
  }

  async getOwner(ownerId) {
    let response = await soloveykoApi.get('/owner', {
      params: {
        owner_id: ownerId
      }
    })
    return response.data.owner
  }

  // TODO: move it into contact api file
  async listAvailableOwners() {
    let response = await soloveykoApi.get("/contact/listOwnersByEmail")
    return response.data.owners
  }

  async isAllowedToCreateOwner() {
    let response = await soloveykoApi.get("/owner/isAllowedToCreateOwner")
    return response.data.is_allowed
  }

  async loadActivityLog(owner_id, date) {
    let response = await soloveykoApi.get("owner/user_actions_history", {
      params: {
        owner_id: owner_id,
        date: date
      }
    })

    return response.data.user_actions
  }
}

export default new OwnerApi()
