
export const ACCESS_LEVEL = {
  GLORY_LORD:       'glory_lord',
  ADMIN:            'admin',
  LOCATION_MANAGER: 'location_manager',
  LISTENER:         'listener'
}

export const ACCESS_LEVEL_OPTIONS = [
  {
    title: "Адміністратор",
    value: "admin"
  },
  {
    title: "Менеджер локації",
    value: "location_manager"
  },
  {
    title: 'Слухач',
    value: 'listener'
  }
]