<template>
  <!-- dropdown base -->
  <div class="relative">
    <label
      class="btn btn-ghost hover:bg-transparent hover:border-transparent hover:text-slate-100 btn-circle m-1"
      v-tippy="{ theme: 'light', content: 'Налаштування'}"
      tabindex="0"
      @click="showDisplayDropdown"
    >
      <Cog6ToothIcon class="w-6 h-6"/>
    </label>
    <!-- dropdown shadow -->
    <div v-if="displayDropdown" 
        class="fixed inset-0 z-50"
        @click="closeDisplayDropdown"
        />
    <!-- dropdown -->
    <div v-if="displayDropdown"
          tabindex="0"
          class="absolute top-full z-[100] card s-card-fill-2 w-72 mr-1 lg:w-60 lg:mr-0 overflow-hidden"
          :class="{
            'sm:left-0':   navBarStore.menuCollapsed,
            'right-0': navBarStore.menuCollapsed
          }">

          <router-link :to="{ name: 'Owner', params: { locale: $i18n.locale } }"
                      class="flex flex-row gap-3 px-4 h-12 items-center hover:s-navbar-item-hover"
                      @click="closeDisplayDropdownAndMobileNavBar">
                  <HomeIcon class="w-6 h-6 shrink-0"/>
                  {{ $t("navbar.owner") }}
          </router-link>

          <router-link :to="{ name: 'Account', params: { locale: $i18n.locale } }"
                      class="flex flex-row gap-3 px-4 h-12 items-center hover:s-navbar-item-hover"
                      @click="closeDisplayDropdownAndMobileNavBar">
                  <UserIcon class="w-6 h-6 shrink-0"/>
                  {{ $t("navbar.account") }}
          </router-link>

          <div class="border-t s-border menu">
            <div class="text-red-400 flex flex-row gap-3 px-4 h-12 items-center hover:s-navbar-item-hover rounded-b-xl cursor-pointer"
                        @click="signOut">
                    <ArrowLeftOnRectangleIcon class="w-6 h-6"/>
                    {{ $t("navbar.signOut") }}
            </div>
          </div>
    </div>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import { useRouter } from "vue-router";
import { Auth } from 'aws-amplify';
import { useNavBarStore } from "@/store/navBarStore";
import { resetPiniaStores } from '@/helpers/storeHelpers';
import { clearAll } from '@/helpers/storageHelpers'


// Icons
import { Cog6ToothIcon, HomeIcon, UserIcon, ArrowLeftOnRectangleIcon } from "@heroicons/vue/24/outline";

const navBarStore = useNavBarStore();

const hideMobileMenu = () => {
    navBarStore.hideMobileMenu();
}

const closeDisplayDropdownAndMobileNavBar = () => {
  closeDisplayDropdown();
  hideMobileMenu();
}

const router = useRouter();

const signOut = async () => {
  closeDisplayDropdown();

  await Auth.signOut();
  router.push({ name: "SignIn" });

  clearAll();
  resetPiniaStores();
};


/* Dropdown control */

const displayDropdown = ref(false);

const showDisplayDropdown = () => {
  displayDropdown.value = true;
}

const closeDisplayDropdown = () => {
  displayDropdown.value = false;
}

</script>

<style scoped lang="scss">
[data-theme="light"] {
    .active,
    .active:hover { 
        background-color: rgb(169,169,89);
        color: rgb(68,68,68);
    }

}
[data-theme="dark"] {
    .active,
    .active:hover {
        background-color: #5127cd;
        color: #fefbf7;
    }
}

</style>
